// Import our Bootstrap extensions
import "bootstrap/dist/js/bootstrap.bundle.min";

import { Tooltip } from 'bootstrap';
import Prism from 'prismjs';
import Alpine from 'alpinejs';
import persist from '@alpinejs/persist'

Prism.highlightAll();
window.Alpine = Alpine;
Alpine.plugin( persist );

document.addEventListener( 'alpine:init', () => {
    Alpine.data( 'coldboxData', function () {
        return {
            isDark: this.$persist( true ).as( 'coldbox-darkmode' ),
            isOpenMenu: false,
            isOpenFeature: false
        }
    })
})

Alpine.start();


// Initialize tooltips
var tooltipTriggerList = [].slice.call( document.querySelectorAll( '[data-bs-toggle="tooltip"]' ) );
var tooltipList = tooltipTriggerList.map( function ( tooltipTriggerEl ) {
  return new Tooltip( tooltipTriggerEl );
});